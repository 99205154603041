<template>
<div class="app-wrapper">

    <div class="app-content pt-3 p-md-3 p-lg-4">

        <div class="modal fade" id="staticLog" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Log Pembayaran</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- {{edit}} -->
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tanggal</th>
                                    <th scope="col">Bayar</th>
                                    <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(post, index) in edit2" :key="index">
                                    <th scope="row">{{index+1}}</th>
                                    <td>{{ filterdate(post.created_at) }}</td>
                                    <td>{{"Rp " + post.bayar.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</td>
                                    <td>
                                        <!-- <button class="btn btn-primary text-center">Hapus</button> -->
                                        <button class="btn btn-primary text-white" @click="prints(invId, index)">Print</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="staticEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Barang</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- {{edit}} -->
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Barang</th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Harga</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(post, index) in edit" :key="index">
                                    <th scope="row">{{index+1}}</th>
                                    <td>{{ post.barang.nama_barang }}</td>
                                    <td>{{ post.qty }}</td>
                                    <td>{{"Rp " + (post.harga_fix * post.qty).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="staticEdit2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Hutang</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-6">
                            <label for="inputPassword4" class="form-label">Nominal</label>
                            <input type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" min="1" :placeholder="'Maximal ' + numberGet  " :max="numberGet" v-model="bayar" :class="bayarHutangmessage.bayar == null ? 'form-control' : 'form-control is-invalid'" id="inputNominal">
                            <div style="color:red;" v-if="bayarHutangmessage.bayar">
                                {{ bayarHutangmessage.bayar[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="simpanHutang" type="button" class="btn btn-secondary">Simpan</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="container-fluid mt-5">
                <div class="row">

                    <div class="col-md-12">
                        <div class="app-card app-card-orders-table shadow-sm mb-5">
                            <div class="app-card-body">
                                <div class="row g-3 mb-4 ms-2 me-2 align-items-center justify-content-between">
                                    <div class="col-12">
                                        <h1 class="app-page-title mb-0">Data Hutang</h1>
                                    </div>
                                    <div class="col-auto">
                                        <div class="page-utilities">
                                            <div class="row g-2 justify-content-start justify-content-md-end align-items-center">
                                                <div class="col-auto">
                                                    <div class="docs-search-form row gx-1 align-items-center">

                                                        <div class="col-8">

                                                            <div class="input-group ">
                                                                <input v-model="search" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Search Nama Pembeli">
                                                                <button @click="getReset" class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> X </button>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 mt-sm-5 mt-md-0">
                                                            <button @click="getSearch" type="submit" class="btn app-btn-secondary">Search</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <!--//col-->

                                            </div>
                                            <!--//row-->
                                        </div>
                                        <!--//table-utilities-->
                                    </div>

                                    <div class="col-12">

                                        <div class="text-center">
                                            <div class="row justify-content-center">
                                                <div class="col-12 col-md-4">
                                                    <span class="badge bg-primary">JUMLAH HUTANG : Rp {{ jums.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00' }}</span>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <span class="badge bg-primary">JUMLAH TERBAYAR : Rp {{ jums2.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00' }}</span>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <span class="badge " :class="totals == 0 ? 'bg-primary' : 'bg-danger'">  SISA : Rp {{ totals.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00' }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="table-responsive m-2">
                                            <table class="table app-table-hover mb-0 text-center">
                                                <thead>
                                                    <tr>
                                                        <th class="cell">No</th>
                                                        <th class="cell">Tanggal</th>
                                                        <th class="cell">Invoice</th>
                                                        <th class="cell">Nama Pembeli</th>
                                                        <th class="cell">Kasir</th>
                                                        <th class="cell">Total</th>
                                                        <th class="cell">Bayar</th>
                                                        <th class="cell">Potongan</th>
                                                        <th class="cell">Sisa</th>
                                                        <th class="cell">Status</th>
                                                        <th class="cell">Aksi</th>
                                                    </tr>
                                                </thead>

                                                <tbody style="border-top: 1px solid grey" v-if="loading === false">

                                                    <tr v-for="(post, index) in posts" :key="index">
                                                        <td class="cell">{{ index +1 }}</td>
                                                        <td class="cell">{{ filterdate(post.created_at) }}</td>
                                                        <td class="cell">{{ post.invoice  }}</td>
                                                        <td class="cell">{{ post.nama_pembeli  }}</td>
                                                        <td class="cell">{{ post.nama_kasir  }}</td>
                                                        <td class="cell">{{"Rp " + (parseInt(total(index))).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</td>
                                                         <td class="cell">{{"Rp " + (parseInt(getbayar(index))).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</td>
                                                        <td class="cell">{{"Rp " + (parseInt(post.potongan)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00' }}</td>
                                                        <td class="cell">
                                                            <span class="badge " :class="(parseInt(getbayar(index)) - parseInt(total(index)) +parseInt(post.potongan)) < 0 ? 'bg-danger' : 'bg-primary'">{{"Rp " + ( parseInt(getbayar(index))-parseInt(total(index))+post.potongan).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00' }}</span>
                                                        </td>
                                                        <td class="cell">
                                                            {{ post.status }}
                                                        </td>
                                                        <td class="text-center cell">
                                                            <!-- Button trigger modal -->

                                                            <button type="button" class="btn btn-warning text-white btn-sm me-2" data-bs-toggle="modal" :data-bs-target="'#staticEdit'" @click="setEdit(index)">
                                                                Lihat Barang
                                                            </button>
                                                            <button type="button" class="btn btn-secondary text-white btn-sm me-2" data-bs-toggle="modal" :data-bs-target="'#staticLog'" @click="setEdit2(index)">
                                                                Log Pembayaran
                                                            </button>
                                                            <button v-if="(getbayar(index) - total(index)+parseInt(post.potongan)) < 0" type="button" class="btn btn-primary text-white btn-sm me-2" data-bs-toggle="modal" :data-bs-target="'#staticEdit2'" @click="setTransaksi(index)">
                                                                Bayar Hutang
                                                            </button>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody style="border-top: 1px solid grey" v-else>
                                                    <tr>
                                                        <td colspan="9" class="text-center " style="height: 200px; padding-top: 100px;">
                                                            <h3> Loading....</h3>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <nav class="app-pagination mt-3">
                                                <ul class="pagination justify-content-center">
                                                    <li :class="[pagination.prev_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks(pagination.prev_page_url)">Previous</a></li>
                                                    <div v-for="item =1 in pagination.last_page" :key="item">
                                                        <li :class="[pagination.current_page == item ?  'active page-item'  : 'page-item'] "><a class="page-link" @click="onClick(item)">{{item}}</a></li>
                                                    </div>
                                                    <li class="page-item" :class="[pagination.next_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks(pagination.next_page_url)">Next</a></li>
                                                </ul>
                                            </nav>
                                            <!--//app-pagination-->

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
    data() {
        return {
            posts: {},
            page: 1,
            pagination: {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            },
            posts2: {},
            page2: 1,
            pagination2: {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            },
            edit: [],
            edit2: [],
            search2: "",
            cabang2: '',
            userid2: '',
            isActive: true,
            isActive2: false,
            link: this.globalApiUrl,
            loading: false,
            loggedIn: localStorage.getItem('loggedIn'),
            token: localStorage.getItem('token'),
            search: "",
            cabang: '',
            userid: '',
            bayarHutangmessage: [],
            idTransaksi: '',
            bayar: '',
            numberGet: 0,
            invId: '',
            jums: 0,
            jums2: 0,
            totals: 0,
            searchCabang: localStorage.getItem('cabang'),
        }

    },
    created() {
        if (!this.loggedIn) {
            if (!this.token) {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                return this.$router.push({
                    name: 'login.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
            }
        } else {
            this.getResults();
        }
    },
    methods: {
        getResults() {
            this.loading = true;
            // console.log(this.loading);
            axios.get(this.link + 'api/transaksi-hutang?limit=10&search=' + this.search + '&cabang=' + this.searchCabang + '&userid=' + this.userid + '&page=' + this.page, {
                    headers: {
                        'Authorization': this.token
                    }
                })
                .then(response => {
                    // console.log(response.data.data.transaksi.data)
                    this.jums = response.data.data.jum;
                    this.jums2 = response.data.data.jum2;
                    this.totals = response.data.data.total;
                    console.log(this.jums);
                    this.posts = response.data.data.transaksi.data;
                    this.pagination = {
                        "total": response.data.data.transaksi.total,
                        "per_page": response.data.data.transaksi.per_page,
                        "current_page": response.data.data.transaksi.current_page,
                        "last_page": response.data.data.transaksi.last_page,
                        "next_page_url": response.data.data.transaksi.next_page_url,
                        "prev_page_url": response.data.data.transaksi.prev_page_url,
                        "from": response.data.data.transaksi.from,
                        "to": response.data.data.transaksi.to,
                    }
                }).then(
                    this.loading = false
                );
            // console.log(this.loading);
        },

        getbayar(a) {
            var harga_fix = 0;
            this.posts[a].log_hutang.forEach(i => {

                harga_fix = harga_fix + i.bayar
            });
            console.log(harga_fix);
            return harga_fix;
        },

        kliklink() {
            this.isActive = true;
            this.isActive2 = false;
        },
        kliklink2() {
            this.isActive = false;
            this.isActive2 = true;
        },
        onClick(a) {
            this.page = a;
            this.getResults();
        },
        onClick2(a) {
            this.page2 = a;
            this.getResults2();
        },
        onClicks(a) {
            console.log(this.loading);
            axios.get(a + '&limit=10&search=' + this.search + '&cabang=' + this.cabang + '&userid=' + this.userid, {
                    headers: {
                        'Authorization': this.token
                    }
                })
                .then(response => {
                    this.posts = response.data.data.transaksi.data;
                    this.jums = response.data.data.jum;
                    this.jums2 = response.data.data.jum2;
                    this.totals = response.data.data.total;
                    console.log(this.jums);
                    this.pagination = {
                        "total": response.data.data.transaksi.total,
                        "per_page": response.data.data.transaksi.per_page,
                        "current_page": response.data.data.transaksi.current_page,
                        "last_page": response.data.data.transaksi.last_page,
                        "next_page_url": response.data.data.transaksi.next_page_url,
                        "prev_page_url": response.data.data.transaksi.prev_page_url,
                        "from": response.data.data.transaksi.from,
                        "to": response.data.data.transaksi.to,
                    }
                }).then(
                    // this.loading = false
                );
        },
        setTransaksi(a) {
            this.idTransaksi = this.posts[a].id;
            var harga_fix = 0;
            this.posts[a].log_hutang.forEach(i => {

                harga_fix = harga_fix + i.bayar
            });
            var harga_fix2 = 0;

            this.posts[a].detail_transaksi.forEach(i => {

                harga_fix2 = harga_fix2 + (i.harga_fix * i.qty* ((100-i.diskon)/100))
            });

            this.numberGet = Math.abs(harga_fix+(this.posts[a].potongan) - harga_fix2);
            console.log(this.idTransaksi);
        },
        simpanHutang() {
            this.bayarHutangmessage = [];
            var form = new FormData();
            form.append('bayar', this.bayar);
            form.append('transaksiid', this.idTransaksi);
            // form.append('supplierid', this.supplierid);

            axios.post(this.link + 'api/transaksi-hutang-bayar', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Berhasil Ditambah`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.getResults();
            }).catch(error => {
                // if(){}
                // this.tambahError = true;
                this.$toast.show(error.response.data.data.error, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                // if()
                this.bayarHutangmessage = error.response.data.data.error;
                console.log(this.tambahMessage);
            })
        },
        setEdit(a) {
            this.edit = this.posts[a].detail_transaksi;
        },
        setEdit2(a) {
            this.invId = this.posts[a].invoice;
            this.edit2 = this.posts[a].log_hutang;
        },
        total(a) {
            var harga_fix = 0;

            this.posts[a].detail_transaksi.forEach(i => {

                harga_fix = harga_fix + (i.harga_fix * i.qty)
            });
            return harga_fix;
        },
        total2(a) {
            var harga_fix = 0;

            this.posts2[a].detail_transaksi.forEach(i => {

                harga_fix = harga_fix + (i.harga_fix * i.qty)
            });
            return harga_fix;
        },

        filterdate(a) {
            // console.log(a);
            return moment(String(a)).format('DD/MM/YYYY')
        },
        prints(a, b) {

            let routeData = this.$router.resolve({
                name: 'hutang.print',
                query: {
                    data: [a, b]

                }
            });
            window.open(routeData.href, '_blank');
        },
        getSearch() {
            this.getResults();
        },
        getReset() {
            this.search = '';
            this.getResults();
        },

    }

}
</script>
